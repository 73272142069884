import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core"

import { Store } from "@ngrx/store"
import { combineLatest, Observable, Subject } from "rxjs"
import { tap, takeUntil, distinctUntilChanged, switchMap, map } from "rxjs/operators"

import * as fromClaimStore from "@app/claims/store"
import * as fromCoreStore from "@app/core/store"
import * as fromUserStore from "@app/users/store"
import * as fromSidebarStore from '@app/sidebar/store'

import {
  User,
  Note,
  OrderVehicle,
  Stop,
  Notification,
  Order,
  LockedItem,
  FOLLOW_UP_RESOURCE
} from "@app/shared/models"
import { OpenModal } from '@app/modals/store/actions/layout.actions'

import { Claim } from "@app/claims/models/claim.model"
import { LockedItemWorkerFactory, LockItemWorker } from "@app/core/services"
import { popupService } from "@app/shared/services/popup.service"

@Component({
  selector: "app-claim",
  template: `
    <div
      *ngIf="{
        claim: claim$ | async,
        coreVals: coreVals$ | async,
        currentUser: currentUser$ | async
      } as observables"
      class="claim-item-container mt-3 mb-3"
    >
      <div *ngIf="observables.claim && observables.coreVals && observables.currentUser" class="">
        <div class="">
          <app-locked-item
            [type]="FOLLOW_UP_RESOURCE.CLAIM"
            *ngIf="order$ | async as order"
            [itemName]="''+observables.claim.autoIncr"
            [lockedItem]="lockedItem$ | async"
            [askToUnlockTimer]="askToUnlockListener$ | async"
            (askToUnlock)="askToUnlock()"
            (updateLock)="updateLock()"
          ></app-locked-item>
          <app-claim-details
            [claimItem]="observables.claim"
            [setOrder]="order$ | async"
            [setStops]="stops"
            [users]="users$ | async"
            [contacts]="contacts$ | async"
            [vehicles]="vehicles"
            [locked]="locked$ | async"
            [currentUser]="currentUser$ | async"
            [lastModifyClaim]="lastModifyUser$ | async"
            [coreVals]="observables.coreVals"
            [contactTypes]="(contactTypes$ | async)?.types"
            [notifications]="notifications$ | async"
            [permissions]="permissions$ | async"
            (onCreate)="create($event)"
            (onUpdate)="update($event)"
            (onRemove)="remove($event)"
            (onSend)="send($event)"
          ></app-claim-details>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
      .claim-item-container {
        @apply w-[98%] ml-auto mr-auto
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimItemComponent implements OnInit, OnDestroy {
  claim$: Observable<Claim>
  order$: Observable<Order>
  stops: Stop[]
  users$: Observable<User[]>
  currentUser$: Observable<User>
  lastModifyUser$: Observable<any>
  // vehicles$: Observable<OrderVehicle[]>
  vehicles: OrderVehicle[]
  destroyed$ = new Subject<boolean>();
  contacts$: Observable<any>
  coreVals$: Observable<any>
  coreVals: any
  contactTypes$: Observable<any>
  notifications$: Observable<Notification[]>
  permissions$: Observable<any>

  lockedItem$: Observable<LockedItem>
  askToUnlockListener$: Observable<number>
  lockedItemWorker: LockItemWorker
  locked$: Observable<boolean>

  FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE
  claim: Claim

  contactIds = [];

  constructor(
    private store: Store<fromClaimStore.State>,
    private lockedItemWorkerFactory: LockedItemWorkerFactory,
    private popupService: popupService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.claim$ = this.store.select(fromClaimStore.selectedClaim)
    this.coreVals$ = this.store.select(fromCoreStore.selectCoreValClaims)

    this.lockedItem$ = this.store.select(fromClaimStore.selectCurrentClaimId).pipe(
      distinctUntilChanged(),
      tap(
        id =>
        (this.lockedItemWorker = this.lockedItemWorkerFactory.createLockItemWorker(
          id,
          FOLLOW_UP_RESOURCE.CLAIM,
          fromCoreStore,
          { changeWindowTitle: true }
        ))
      ),
      switchMap(() => this.lockedItemWorker.listenAndLock())
    )
    this.locked$ = this.store.select(fromClaimStore.selectCurrentClaimId).pipe(
      switchMap(id => this.store.select(fromCoreStore.checkLockedItemLock(id))
        .pipe(
          map(lock => !lock.lockedByCurrentTab)
        )
      )
    )

    this.lastModifyUser$ = this.store.select(fromClaimStore.selectUserClaimModify)
    this.order$ = this.store.select(fromClaimStore.selectOrderOfCurrentClaim)

    this.store
      .select(fromCoreStore.selectAllStops)
      .pipe(
        takeUntil(this.destroyed$),
        tap(resp => {
          this.stops = resp
        })
      )
      .subscribe()

      this.store
      .select(fromCoreStore.selectAllVehicles)
      .pipe(
        takeUntil(this.destroyed$),
        tap((vehicles: any) => {
          for(let v of vehicles) {
            v.showDetails = false
          }
          this.vehicles = vehicles
        })
      )
      .subscribe()

    // this.vehicles$ = this.store.select(fromCoreStore.selectAllVehicles)

    this.currentUser$ = this.store.select(fromUserStore.getUser)

    this.users$ = this.store.select(fromSidebarStore.selectAllUsers)
    this.contactTypes$ = this.store.select(fromCoreStore.selectCoreValContacts)

    this.contacts$ = this.store.select(fromCoreStore.selectAllContacts)
    // this.coreVals$ = this.store.select(fromCoreStore.selectCoreValClaims


    this.notifications$ = this.store.select(fromCoreStore.selectAllNotifications)

    this.permissions$ = this.store.select(fromUserStore.getPermissions)
  }

  askToUnlock = () => (this.askToUnlockListener$ = this.lockedItemWorker.askToUnlock())
  updateLock = () => this.lockedItemWorker.updateLock()

  create(event) {
    this.store.dispatch(new fromClaimStore.CreateClaim({ claim: event }))
  }

  update(event: Claim) {
    this.store.dispatch(
      new fromClaimStore.UpdateClaim({
        claim: { id: event._id, changes: event }
      })
    )
  }

  async remove(event: Claim) {
    const remove = await this.popupService.confirm("Are you sure you want to delete?")
    if (remove) {
      this.store.dispatch(
        new fromClaimStore.DeleteClaim({ claim: event, redirect: true })
      )
    }
  }

  send(data) {
    this.store.dispatch(
      new OpenModal({
        type: "NOTIFICATION",
        props: {
          order: data.claim,
          _id: data.claim._id,
          files: data.files ? data.files : null,
          resource: FOLLOW_UP_RESOURCE.CLAIM,
          disableAutoAttachment: true
        }
      })
    )
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
